<template>
  <div>
    <!-- Contenedor Bienvenida -->
    <div
      ref="bienvenidaRef"
      class="bienvenida-container"
    >
      <p class="bienvenida-text">
        {{ mensaje }}
      </p>
      <p
        v-if="mostrarMensajeFinal"
        class="textmia"
      >
        {{ mensajeFinal }}
      </p>
      <div class="icono-container">
        <i class="fas fa-leaf icono" />
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import separadorImg from '@/assets/separador1.png';

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);
    const mensaje = ref(""); // Primer mensaje a mostrar
    const mensajeFinal = ref(""); // Segundo mensaje a mostrar
    const text = "\"Amar a alguien es una cosa. Que alguien te ame es otra. Pero que te ame la misma persona que amas lo es todo.\" \n \n\nTodos los dias juntos son maravillosos y queremos que nos acompañes en el dia mas importante para nosotros siendo parte de nuestros recuerdos.";
    const textFinal = ""; // Texto final a mostrar
    let hasStartedWriting = false;
    const mostrarMensajeFinal = ref(false);

    const escribirTexto = (texto, index, callback) => {
      if (index < texto.length) {
        mensaje.value += texto.charAt(index);
        setTimeout(() => escribirTexto(texto, index + 1, callback), 50);
      } else if (callback) {
        callback();
      }
    };

    const escribirTextoFinal = (texto, index) => {
      if (index < texto.length) {
        mensajeFinal.value += texto.charAt(index);
        setTimeout(() => escribirTextoFinal(texto, index + 1), 50);
      }
    };

    onMounted(async () => {
      await nextTick();

      if (bienvenidaRef.value) {
        const element = bienvenidaRef.value;

        // Observador para detectar cuando el elemento entra en la vista
        const observerOptions = {
          root: null,
          rootMargin: '0px',
          threshold: [0.1]
        };

        const observerCallback = (entries) => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              if (!hasStartedWriting) {
                setTimeout(() => {
                  escribirTexto(text, 0, () => {
                    mostrarMensajeFinal.value = true;
                    escribirTextoFinal(textFinal, 0);
                  });
                }, 500);
                hasStartedWriting = true;
              }
            }
          });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);
        observer.observe(element);
      }
    });

    return { bienvenidaRef, mensaje, mensajeFinal, mostrarMensajeFinal, separadorImg };
  }
};
</script>

<style scoped>
/* Contenedor Bienvenida */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJqaE0lK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.bienvenida-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  background-image: linear-gradient(rgba(228, 217, 199, 0.95), rgba(228, 217, 199, 0.95)), url(@/assets/bg-palma-5.png); /* Gradiente y fondo de imagen */
  background-color: #e4d9c7; /* Color de fondo para áreas sin imagen */
  background-position: 50% 50%; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-size: cover; /* Ajusta la imagen para que cubra todo el contenedor */
  padding: 0 20px; /* Padding para separar el contenido de los bordes */
  width: 100%; /* Ocupa todo el ancho del contenedor */
  box-sizing: border-box; /* Asegura que el padding no afecte el ancho total */
  position: relative; /* Permite posicionamiento absoluto para los elementos hijos */
  padding-top: 30px;
  padding-bottom: 0; /* Asegúrate de que no haya padding abajo */
  margin: 0; /* Elimina cualquier margen alrededor del contenedor */
  text-align: center;
  opacity: 1; /* Mantiene el contenedor visible */
  z-index: 1;
}

/* Estilos para el texto de bienvenida */
.bienvenida-text {
  font-family: 'karla'; /* Cambia la fuente a MonteCarlo */
  font-size: 16px; /* Ajusta el tamaño de la fuente como desees */
  color: #726c55 !important; 
  margin: 0; /* Elimina margen para un mejor control del espaciado */
  margin-bottom: 40px;
  margin: 0 30px;
  font-weight: 600;
  white-space: pre-line;
}

.textmia {
  font-family: 'FeelingPassionate';
  font-size: 34px;
  opacity: 1; /* Asegura que el texto esté visible */
  color: #917087d4;
  margin-top: 10px;
  transition: opacity 0.3s ease;
}

.icono {
  color: #2f553e !important; 
  font-size: 20px; /* Ajusta este valor según el tamaño deseado */
  margin-top: 20px;
}
</style>
