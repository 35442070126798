<template>
  <div class="footer-container">
    <div class="footer-content">
      <div class="footer-text">
        Hecho con amor:  <br>MI INVITACIÓN DIGITAL & MISS COQUETTE 
      </div>
      <a
        href="https://www.facebook.com/share/YYdTE6Xnc1Qzb1z4/?mibextid=LQQJ4d"
        class="footer-link"
        target="_blank"
        rel="noopener noreferrer"
      >¡Contactanos!</a>
    </div>
    <!-- Nueva sección de íconos debajo del footer -->
    <div class="footer-icons">
      <a
        href="https://www.facebook.com/profile.php?id=61564820404741"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="@/assets/facebook.png"
          alt="Facebook"
          class="footer-icon"
        >
      </a>
      <a
        href="https://www.instagram.com/myinvitaciondigital"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="@/assets/instagram.png"
          alt="Instagram"
          class="footer-icon"
        >
      </a>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    // Aquí puedes agregar lógica adicional si es necesario
  },
};
</script>

<style scoped>
/* Contenedor del pie de página */
.footer-container {
  background-image: linear-gradient(rgba(228, 217, 199, 0.95), rgba(228, 217, 199, 0.95)), url(@/assets/bg-palma-5.png); /* Gradiente y fondo de imagen */
  background-color: #e4d9c7; /* Color de fondo para áreas sin imagen */
  background-position: 50% 50%; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-size: cover; /* Ajusta la imagen para que cubra todo el contenedor *
  color: white; /* Texto blanco */
  padding: 20px; /* Espaciado interno */
  display: flex;
  flex-direction: column; /* Apilar el contenido de arriba hacia abajo */
  justify-content: center;
  align-items: center;
  width: 100vw; /* Ocupar el 100% del ancho de la ventana */
  font-weight: 200;
  margin: 40; /* Elimina márgenes para evitar desplazamiento */
  overflow: hidden; /* Oculta el desbordamiento */
  background-size: 250%; /* Ampliará la imagen al 150% de su tamaño original */

}

/* Contenido del pie de página (imagen y texto) */
.footer-content {
  display: flex;
  align-items: center;
  width: 100%;
  
}

/* Estilo de la imagen */
.footer-image {
  width: 100px;
  height: auto;
  margin-right: 10px;
}

/* Estilo del texto */
.footer-text {
  font-size: 12px;
  text-align: center;
}

/* Nueva sección de íconos */
.footer-icons {
  margin-top: 20px; /* Espacio entre el footer y los íconos */
  display: flex;
  gap: 10px; /* Espacio entre los íconos */

}

/* Estilo de los íconos */
.footer-icon {
  width: 30px; /* Tamaño pequeño para los íconos */
  height: auto;
}

/* Estilos para pantallas pequeñas (por ejemplo, móviles) */
@media (max-width: 576px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
  }

  .footer-image {
    margin-right: 0;
    margin-bottom: 10px;
  }
}

/* Estilos para pantallas medianas (tablets, etc.) */
@media (min-width: 577px) and (max-width: 768px) {
  .footer-image {
    width: 80px;
  }
}

/* Estilos para pantallas grandes */
@media (min-width: 769px) {
  .footer-image {
    width: 100px;
  }
}


.footer-link {
  font-size: 12px; /* Tamaño pequeño */
  font-weight: 300;
  color: #498080; 
  text-decoration: none; /* Sin subrayado */
  transition: color 0.3s; /* Transición suave para el cambio de color */
}

.footer-link:focus, .footer-link:hover {
 color: #498080; 
}
</style>
