<template>
  <div @click="handleClick"> 
    <!-- Navbar -->
    <NavbarComponent v-if="showNavbar" />
    <!-- Imagen de portada -->
    <BienvenidaPage v-if="showBienvenida" />

    <padresevento v-if="showpadresevento" />
    <dondeycuando v-if="showdondeycuando" />
    <carruselComponent v-if="showcarrusel" />
    <itinerario v-if="showitinerario" />
    <codigovestimenta v-if="showcodigovestimenta" />

    
    <!-- Comprobar si idInvitado es válido antes de mostrar el componente InvitadoEvento -->
    <InvitadoEvento 
      v-if="showInvitadoEvento && isValidIdInvitado" 
      :id-invitado="Number(idInvitado)" 
      :nombre="nombreVariable" 
      :id-festejado="Number(idFestejado)" 
    />
    
    <confirmacionasistencia
      v-if="showInvitadoEvento && isValidIdInvitado" 
      :id-invitado="Number(idInvitado)" 
      :nombre="nombreVariable" 
      :id-festejado="Number(idFestejado)" 
    />
    <hospedaje v-if="showhospedaje" />
    <quotemensaje v-if="showquotemensaje" />
    <blogevento
      v-if="showblogevento"
      :id-invitado="Number(idInvitado)" 
      :nombre="nombreVariable" 
      :id-festejado="Number(idFestejado)" 
    />
    <imgfinal v-if="showimgfinal" />
    <img_fixed v-if="showimg_fixed" />
    <musicaFondo v-if="showmusicaFondo" />
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, computed } from 'vue';
import { useRoute } from 'vue-router';
import NavbarComponent from './narvar.vue';
import BienvenidaPage from './BienvenidaPage.vue';
import padresevento from './padresevento.vue';
import dondeycuando from './dondeycuando.vue';
import itinerario from './itinerario.vue';
import carruselComponent from './carruselComponent.vue';
import codigovestimenta from './codigovestimenta.vue';
import InvitadoEvento from './invitado_evento.vue';
import confirmacionasistencia from './confirmaciónasistencia.vue';
import hospedaje from './hospedaje.vue';
import blogevento from './blog_evento.vue';
import quotemensaje from './quotemensaje.vue';
import imgfinal from './imgfinal.vue';
import img_fixed from './img_fixed.vue';
import musicaFondo from './musica_fondo.vue';
export default {
  name: 'BaseB',
  components: {
    NavbarComponent,
    BienvenidaPage,
    padresevento,
    dondeycuando,
    itinerario,
    carruselComponent,
    codigovestimenta,
    InvitadoEvento,
    confirmacionasistencia,
    hospedaje,
    quotemensaje,
    blogevento,
    imgfinal,
    img_fixed,
    musicaFondo,
  },
  setup() {
    const showNavbar = ref(false);
    const showBienvenida = ref(false);
    const showpadresevento=ref(false);
    const showdondeycuando=ref(false);
    const showitinerario=ref(false);
    const showcarrusel=ref(false);
    const showcodigovestimenta=ref(false);
    const showInvitadoEvento=ref(false);
    const showconfirmacionasistencia=ref(false);
    const showhospedaje=ref(false);
    const showquotemensaje=ref(false);
    const showblogevento=ref(false);
    const showimgfinal=ref(false);
    const showimg_fixed=ref(false);
    const showmusicaFondo=ref(false);

    // Obtener el parámetro idInvitado de la ruta
    const route = useRoute();
    const idInvitado = ref(Number(route.params.idInvitado) || undefined); // Si no hay parámetro, es undefined
    const idFestejado = ref(Number(route.params.idFestejado) || undefined); // Si no hay parámetro, es undefined
    const nombreVariable = ref(route.params.nombre) ;

    // Verificar si el idInvitado es válido (no NaN o undefined)
    const isValidIdInvitado = computed(() => {
      return !isNaN(idInvitado.value) && idInvitado.value !== undefined;
    });

    const isValidFestejado = computed(() => {
      return !isNaN(idIFestejado.value) && idFestejado.value !== undefined;
    });


    // Observa los cambios en la ruta para actualizar idInvitado
    watch(() => route.params.idInvitado, (newId) => {
  idInvitado.value = Number(newId);
});

watch(() => route.params.idFestejado, (newId) => {
  idFestejado.value = Number(newId);
});
watch(() => route.params.nombre, (newId) => {
  nombreVariable.value = (newId);
});

    const handleClick = () => {
      console.log('idInvitado:', idInvitado.value); // Verifica el valor aquí
      console.log('idFestejado:', idFestejado.value); // Verifica el valor aquí
      console.log('NOMBRE:', nombreVariable.value); // Verifica el valor aquí

      showNavbar.value = true;
      showBienvenida.value = true;
      showpadresevento.value=true;
      showdondeycuando.value = true;
      showitinerario.value = true;
      showcarrusel.value = true;
      showcodigovestimenta.value = true;
      showInvitadoEvento.value = true;
      showconfirmacionasistencia.value = true;
      showhospedaje.value=true;
      showquotemensaje.value=true;
      showblogevento.value=true;
      showimgfinal.value=true;
      showimg_fixed.value = true;
      showmusicaFondo.value=true;
    };

    onMounted(() => {
      document.addEventListener('click', handleClick);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClick);
    });

    return {
      showNavbar,
      showBienvenida,
      showpadresevento,
      showdondeycuando,
      showitinerario,
      showcarrusel,
      showcodigovestimenta,
      showInvitadoEvento,
      showconfirmacionasistencia,
      showhospedaje,
      showquotemensaje,
      showblogevento,
      showimgfinal,
      showimg_fixed,
      showmusicaFondo,
      idInvitado,
      isValidIdInvitado, 
      idFestejado,
      nombreVariable,
      isValidFestejado, // Computed que indica si el idInvitado es válido
      handleClick,
    };
  }
};
</script>

<style scoped>
/* Estilos globales para el contenedor */
</style>
