<template>
  <div>
    <!-- Contenedor Bienvenida -->
    <div class="bienvenida-container">
      <div class="overlay">
        <div class="tex1" />
      </div>

      <!-- Segundo Overlay debajo del primero -->
      <div
        ref="bienvenidaRef"
        class="overlay2"
      >
        <div
          ref="rightIconRef"
          class="icon"
        >
          <img
            src="@/assets/sobre.png"
            alt="icon-right"
            class="icon-image pulso" 
          >
        </div>
        <div
          ref="rightIconRef"
          class="icon"
        />
      </div>

      <div class="text2" />
      <div class="text3">
        <br> ¡Que nos acompañes es lo mas importante! Y si esta en tu disposición realizar una muestra de cariño estaremos muy agradecidos. <br><br><br>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import separadorImg from '@/assets/separador1.png';

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvenidaRef = ref(null);
    
    onMounted(async () => {
      await nextTick(); // Asegurarte de que el DOM esté completamente actualizado
    });

    return { bienvenidaRef, separadorImg };
  }
};
</script>

<style scoped>
/* Contenedor Bienvenida */
.bienvenida-container {
  position: relative;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin: 0;
  z-index: 1;
  font-family: 'Dancing Script', cursive;
  font-size: 26px;
  background: linear-gradient(to bottom, rgba(222, 230, 213, 0.078) 30%, rgba(101, 114, 96, 0.212));
  font-weight: 300;
  text-align: center;
  display: flex;
  flex-direction: column;
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  gap: 20px;
  box-sizing: border-box; /* Asegura que el padding no agregue más ancho */
  overflow-x: hidden; /* Evita el desbordamiento horizontal */
}

/* Contenedor Overlay */
.overlay, .overlay2 {
  border-radius: 15px;
  padding: 20px;
  width: 90%; /* Se ajusta al 90% del ancho de la pantalla */
  max-width: 600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column; /* Cambia a columna para que el contenido se apile verticalmente */
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #9c916b !important; 
  font-size: 20px;
  letter-spacing: 2px; /* Espaciado entre letras */
  font-family: "Oswald", Sans-serif;

  flex-direction: row; /* Alinea los iconos en una fila horizontal */
  margin-top:-40px;
  font-weight: 500;
  margin: 0 30px;
  
}
@keyframes animateText {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}
/* Elementos dentro de overlay */
.tex1 {
  margin-top: 10px;
  text-align: center; /* Alinea el texto horizontalmente al centro */
  background: linear-gradient(270deg, #9c916b,  #ded7bc, #5c5640);
  background-size: 200% 100%;
  background-clip: text;
  color: transparent;
  animation: animateText 5s linear infinite;
  margin-bottom: -50px;
}

.text2 {
  font-size: 22px;
  font-weight: 200;
  margin-top:-30px;
  color: #9c916b !important; 
  font-family: 'Great Vibes', cursive !important;

}

/* Estilo del icono */
.icon {
  margin-top: -20px;
  margin: 20px 0; /* Añade margen vertical a los iconos */
}

.icon-image {
  width: 60px;
  height: auto;
}

/* Animación de pulso */
@keyframes pulso {
  0%, 100% {
    transform: scale(1); /* Tamaño original */
  }
  50% {
    transform: scale(1.1); /* Aumenta el tamaño en un 10% */
  }
}

/* Aplicar animación de pulso */
.pulso {
  animation: pulso 1.5s infinite; /* Duración y que se repita infinitamente */
}

/* Centrar la imagen separador */
.separador-img {
  display: block; /* Asegura que la imagen ocupe el ancho completo */
  margin: 20px 0; /* Agrega márgenes superior e inferior */
}
.text3 {
  font-family: "Oswald", Sans-serif;
  color:#6c6858;
  margin-left: 30px; /* Ajusta el valor según lo necesario */
  margin-right: 30px; /* Ajusta el valor según lo necesario */
  font-size: 18px;
  margin-top:-60px;
}

</style>
