<template>
  <div>
    <!-- Barra de navegación con transición -->

    <!-- Imagen de portada con texto -->
    <transition name="fade">
      <div
        v-if="isVisible"
        class="fotoportada-container animate__animated animate__fadeIn"
      >
        <div>
          <img
            :src="require('@/assets/5.jpg')"
            alt="Globo"
            class="rounded-circle"
          >
        </div>
        <div class="overlay">
          <!-- Imagen superpuesta encima de la imagen de fondo -->
          <!-- Texto centrado sobre la imagen -->
          <p class="centered-text">
            <span>Yamiler</span> <span class="ampersand">&</span> <span>Marco Vinicio</span>
          </p>

          <p class="text-eucalipto">
            ¡NOS CASAMOS!
          </p>
          <div class="countdown-container">
            <transition name="fade">
              <div
                v-if="isVisible"
                class="countdown animate__animated animate__fadeInUp"
              >
                <div class="countdown-item">
                  <span>{{ countdownText.substring(0, 2) }}</span>
                  <label>Dias</label>
                </div>
                <div class="countdown-item">
                  <span>{{ countdownText.substring(3, 5) }}</span>
                  <label>Horas</label>
                </div>
                <div class="countdown-item">
                  <span>{{ countdownText.substring(6, 8) }}</span>
                  <label>Min</label>
                </div>
                <div class="countdown-item">
                  <span>{{ countdownText.substring(9, 11) }}</span>
                  <label>Seg</label>
                </div>
              </div>
            </transition>
            <a
              href="#inicio"
              class="smoothscroll"
            >
              <svg
                class="vert-move"
                xmlns="http://www.w3.org/2000/svg"
                width="54"
                height="24"
                fill="white"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 16.5l-6-6h12z"
                  stroke="white"
                  stroke-width="2"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </transition>

    <!-- Contenedor de cuenta regresiva justo debajo de la imagen de portada -->
    <div class="seccion2">
      <div class="image-container">
        <section
          class="site-section pt-0"
          style="position: relative; background-color: #e4d9c7;"
        >
          <img 
            src="@/assets/5.jpg" 
            alt="Fondo Palma" 
            class="background-image"
          >
        </section>
      </div>

      <h1
        ref="textFaltanRef"
        class="textfaltan"
      >
        {{ numero }} {{ fechaevento }}
      </h1>
      <div
        ref="textRef"
        class="textfaltan"
      />
    </div>
  </div>
</template>

<script>
import portadaP from '../assets/5.jpg';
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  name: 'NavbarComponent',
  setup() {
    const fotoPortada = portadaP;
    const isVisible = ref(true);
    const text = ref('M | J');
    const showMenu = ref(false);
    const isNavbarHidden = ref(false);
    const scrollThreshold = ref(100);
    let lastScrollTop = 0;
    const countdownText = ref('00:00:00:00');
    const fechaevento = ref('Diciembre 2024');
    const textRef = ref(null);
    const numero = ref(1);
    const textsobreimagen = ref('Yamiler   &   Marco Vinicio');
    let countdownInterval;
    let interval;

    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop && currentScrollTop > scrollThreshold.value) {
        isNavbarHidden.value = true;
      } else if (currentScrollTop < lastScrollTop) {
        isNavbarHidden.value = false;
      }

      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop;
    };

    const toggleMenu = () => {
      showMenu.value = !showMenu.value;
    };

    const updateCountdown = () => {
      const endDate = new Date('2024-12-28T18:00:00');
      const now = new Date();
      const difference = endDate - now;
      if (difference <= 0) {
        clearInterval(countdownInterval);
        countdownText.value = '00:00:00:00';
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      countdownText.value = `${String(days).padStart(2, '0')} ${String(hours).padStart(2, '0')} ${String(minutes).padStart(2, '0')} ${String(seconds).padStart(2, '0')}`;
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
      updateCountdown();
      countdownInterval = setInterval(updateCountdown, 1000);

      const target = 28; // Número objetivo que queremos alcanzar

      const elements = [textRef.value]; // Cambia esto si tienes más elementos que observar

      const observerOptions = {
        root: null, // Usar el viewport como raíz
        rootMargin: '0px',
        threshold: [0.4] // Activar cuando el 40% del elemento sea visible
      };

      const observerCallback = (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            // Reiniciar el número y el intervalo de incremento al ser visible
            numero.value = 1; // Reinicia el número a 1
            if (!interval) { // Solo establece el intervalo si no está ya configurado
              interval = setInterval(() => {
                if (numero.value < target) {
                  numero.value++; // Incrementa el número en 1
                } else {
                  clearInterval(interval); // Detiene el intervalo cuando alcanza el objetivo
                  interval = null; // Reinicia el intervalo a null
                }
              }, 40);
            }
          } else {
            clearInterval(interval); // Detener el intervalo cuando el elemento sale del viewport
            interval = null; // Reinicia el intervalo a null
          }
        });
      };

      const observer = new IntersectionObserver(observerCallback, observerOptions);

      // Observar cada elemento
      elements.forEach(element => {
        if (element) {
          observer.observe(element);
        }
      });
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
      clearInterval(countdownInterval);
      clearInterval(interval); // Asegúrate de limpiar el intervalo
    });

    return {
      numero, // Retorna el número para que sea accesible en el template
      fechaevento,
      textsobreimagen,
      textRef,
      countdownText,
      fotoPortada,
      isVisible,
      text,
      showMenu,
      toggleMenu,
      isNavbarHidden,
      scrollThreshold
    };
  },
};
</script>



<style scoped>


.navbar.hidden {
  top: -80px;
}

/* Texto animado */
.animated-text {
  flex-grow: 1;
  text-align: center;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 5s ease;
}

.fade-enter, .fade-leave-to /* .fade-leave-active en Vue <2.1.8 */ {
  opacity: 0;
}

.animated-text p {
  font-family: 'Dancing Script', cursive;
  font-size: 24px;
  color: #4a3f3fef;
  animation: scaleText 1s infinite alternate;
}

@keyframes scaleText {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

/* Estilo del menú desplegable */


.menu a {
  text-decoration: none;
  color: #333;
  font-size: 18px;
}

.menu a:hover {
  color: #007bff;
}

/* Asegurar visibilidad del ícono de menú hamburguesa */
.menu-icon {
  cursor: pointer;
  font-size: 24px;
  color: #333;
  position: absolute; /* Cambié a posición absoluta */
  top: 10px; /* Ajusté la posición para que siempre esté visible */
  right: 20px; /* Alineado a la derecha */
  z-index: 1000; /* Para que esté por encima de otros elementos */
}




/* Contenedor de la portada */
.fotoportada-container {
  position: relative;
  width: 100%;
  min-height: 100vh; /* Resta la altura de la barra de navegación */
  overflow: hidden;
  margin-top: -80px; /* Ajusta según la altura de la barra de navegación */

  /* Degradado rosado en la parte inferior */
  background: linear-gradient(to bottom, rgba(24, 24, 23, 0.078) 30%, rgba(101, 114, 96, 0.212)),
  url('../assets/bg-oliva-1.jpg');
  
  background-size: cover; /* Cubre todo el contenedor */
  background-repeat: no-repeat; /* No repetir la imagen */
  background-position: top center; /* Alinea la imagen a la parte superior y centrada horizontalmente */
  z-index: 1; /* Asegúrate de que esté detrás del contenido superpuesto */
}

  


  /* Animación de acercamiento */
@keyframes popupFadeIn {
  0% {
    opacity: 0;
    transform: scale(0.8); /* Comienza más pequeño */
  }
  100% {
    opacity: 1;
    transform: scale(1); /* Termina en su tamaño original */
  }
}
.overlay {
  position: absolute; /* Cambiado a absolute para cubrir toda la imagen */
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Centra el contenido en el medio */
  color: rgba(255, 255, 255, 0);
  
  z-index: 4;
}


.fotoPortada {
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: 'Daydream';
  src: url('../assets/fonts/daydream.ttf') format('truetype');  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJqaE0lK.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}



@keyframes animateText {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}


.text {
  font-size: 18px;
  text-align: center;
  color: rgba(255, 255, 255, 0.95);
  margin: 0 10px; /* Ajusta el espaciado entre la línea y el texto */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Karla'; /* Cambia la familia de fuentes según tu necesidad */
  opacity: 0; /* Para que empiece invisible */
  transform: translateY(80px);
  animation: fadeInUp 1s ease forwards; /* Controla la duración y suavidad */
  animation-delay: 0.5s; /* Retraso de 0.2 segundos para asegurar visibilidad */
}

.line {
  content: " ";
  display: block;
  width: 40px; /* Ajusta el largo de la línea */
  height: 2px; /* Grosor de la línea */
  background-color: #ebe5e5a1; /* Color de la línea */
   /* Añadir la animación aquí */
   opacity: 0; /* Para que empiece invisible */
  transform: translateY(80px);
  animation: fadeInUp 1s ease forwards; /* Controla la duración y suavidad */
  animation-delay: 0.5s; /* Retraso de 0.2 segundos para asegurar visibilidad */

}

.centered-fechatext {
  font-size: 10px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2px;
  color: rgba(237, 230, 230, 0.934); /* Color del texto */
  font-family: 'karla';
  letter-spacing: 2px;
  /* Estilos de fondo */
  padding-left: 0;
  padding-right: 0;
  background-color: rgba(83, 72, 72, 0.5); /* Fondo semitransparente */
  border-radius: 8px; /* Bordes redondeados */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Sombra para profundidad */
  max-width: 80%; /* Ancho máximo para que no ocupe toda la pantalla */
}
.countdown-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  /* Imagen de fondo */
  background-size: cover;
  padding: 0 20px; /* Agregar padding para evitar que el contenido esté pegado a los bordes */
  width: 100%; /* Ocupará todo el ancho disponible */
  box-sizing: border-box; /* Asegúrate de que el padding no afecte el ancho total */
}
.countdown {
  display: flex;
  gap: 14px; /* Espacio entre los elementos */
  font-size: 16px;
  color: rgb(23, 22, 22);
  margin-left: 0; /* Elimina el margen izquierdo */
  margin-right: 0; /* Elimina el margen derecho */
  opacity: 0; /* Comienza oculto */
  transition: opacity 0.3s ease;
  border-radius: 12px; /* Bordes redondeados */
  padding: 10px; /* Espaciado interno */
}

.countdown-item {
  width: 50px; /* Ajusta el tamaño del item */
  height: 50px; /* Mantiene la proporción para un círculo */
  border-radius: 50%; /* Hace que el item sea circular */
  padding: 8px 0; /* Ajusta el espaciado interno */
  text-align: center;
  background: rgba(255, 255, 255, 0.304); /* Fondo blanco semi-transparente */
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.219); /* Sombra para el elemento */
  margin-bottom: 40px;

}

.countdown-item span {
  font-family: "Oswald", Sans-serif;
  font-weight: lighter;
  letter-spacing: .125rem;
margin-top:2px;
  display: block;
  font-size: 16px;
  color: #9c916b !important; 
font-weight: 400;
}

.countdown-item label {
  font-family: "Oswald", Sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 15px;  
  
margin-top:26px;
  color: #9c916b !important; 
  font-weight: lighter;
  letter-spacing: .125rem;
}



.rounded-circle {
  position: relative; /* Asegura que el desplazamiento se aplique */
  width: 150px; /* Ajusta el tamaño del círculo como prefieras */
  height: 150px;
  margin-top:20vh;
  border-radius: 50% !important;
  object-fit: cover; /* Hace que la imagen se recorte en el círculo */
  overflow: hidden; /* Asegura que la imagen no se salga del círculo */
  outline: 3px solid #9c916b;
}



  .animated-text p {
    font-size: 20px; /* Reduce el tamaño del texto en pantallas pequeñas */
  padding-left: 0;
  padding-right: 0;

  margin: 0;

  display: flex;

}
.animated-text {
  display: flex; /* Asegúrate de que el contenedor tenga display: flex */
  justify-content: center; /* Centra el contenido horizontalmente */
  align-items: center; /* Centra el contenido verticalmente */
  text-align: center; /* Asegúrate de que el texto esté centrado */
}



.animate__fadeInUp {
  animation: fadeInUp 0.5s forwards; /* Cambia la duración como necesites */
}

@keyframes fadeInUp {
  from {
    transform: translateY(20px); /* Comienza 20px más abajo */
    opacity: 0; /* Comienza oculto */
  }
  to {
    transform: translateY(0); /* Vuelve a su posición original */
    opacity: 1; /* Se hace visible */
  }
}

.fade-leave-active {
  opacity: 0; /* Se desvanecerá */
  transition: opacity 0.3s ease;
}




/* Animación de entrada desde abajo */
.fade-in-up {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease forwards; /* Duración y suavizado */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Empieza desplazado 20px hacia abajo */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Llega a la posición final */
  }
}
.superposed-image {
  position: absolute; /* Mantiene la imagen posicionada absolutamente dentro del contenedor */
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  z-index: 5;
  
}

.textfaltan {
  font-family: 'Dancing Script', cursive;
  font-size: 20px;
  font-weight: 800;
  margin-top: 10px;
  background: linear-gradient(270deg, #8b7d57, #616147, #777849);
  background-size: 200% 100%;
  background-position: 0 0;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: gradientAnimation 5s ease infinite; /* Animación */
}

@keyframes gradientAnimation {
  0% {
    background-position: 200% 0; /* Inicia desde la derecha */
  }
  100% {
    background-position: 0 0; /* Termina a la izquierda */
  }}
  .animate-slideInLeft {
  animation: slideInLeft 1s ease-out;
}

.text-eucalipto{
  font-family: "Oswald", Sans-serif;
  font-weight: 200;
font-size: 20px;
  color: #498080  !important; 
}  


@keyframes mover {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(10px); /* Ajusta este valor según sea necesario */
    }
}

.vert-move {
    -webkit-animation: mover 1s infinite alternate;
    animation: mover 1s infinite alternate;
}
.image-container {
  display: flex; /* Para centrar el contenido */
  justify-content: center; /* Centrar horizontalmente */
  align-items: center; /* Centrar verticalmente si se usa altura */
  height: 30vh; /* Ajusta según lo que necesites */
}

.background-image {
  max-width: 300px; /* Ajusta el tamaño máximo de la imagen */
  width: 100%; /* La imagen se ajustará a 100% del contenedor */
  height: auto; /* Mantiene la proporción de la imagen */
  border-radius: 10px; /* Bordes redondeados (opcional) */
}
.seccion2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
  background-image: linear-gradient(rgba(228, 217, 199, 0.95), rgba(228, 217, 199, 0.95)), url(@/assets/bg-palma-5.png); /* Gradiente y fondo de imagen */
  background-color: #e4d9c7; /* Color de fondo para áreas sin imagen */
  background-position: 50% 50%; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  background-size: cover; /* Ajusta la imagen para que cubra todo el contenedor */
  padding: 0 20px; /* Padding para separar el contenido de los bordes */
  width: 100%; /* Ocupa todo el ancho del contenedor */
  box-sizing: border-box; /* Asegura que el padding no afecte el ancho total */
  position: relative; /* Permite posicionamiento absoluto para los elementos hijos */
  background-size: 250%; /* Ampliará la imagen al 150% de su tamaño original */

}
html, body {
  overflow-x: hidden !important;
}

.centered-text .ampersand {
  font-family: 'starlove', cursive !important;
font-size:3rem;
  margin-left: 5px; /* Espacio a la izquierda */
  margin-right: 5px; /* Espacio a la derecha */}

  .centered-text {
  position: relative;
  font-family: 'Great Vibes', cursive !important;
  font-size: 32px;
  margin-top:-60vh;
  font-weight:100;
  text-align: center;
  color: #9c916b !important; 

  opacity: 0; /* Para que empiece invisible */
  transform: translateY(80px);
  animation: fadeInUp 1s ease forwards; /* Controla la duración y suavidad */
  animation-delay: 0.5s; /* Retraso de 0.2 segundos para asegurar visibilidad */

}
</style>