<template>
  <section
    id="itinerario"
    class="site-section "
  >
    <div class="container">
      <!-- Título con imagen -->
      <div
        class="col-12 text-center"
        data-aos="fade-up"
        data-aos-delay=""
      >
        <img
          class="img-fluid mb-0 mt-5"
          src="@/assets/icon-timeline.png"
          width="90px"
          alt="icono itinerario"
        >
        <h2 class="mb-3 text-center euforia text-bronce">
          Itinerario
        </h2>
      </div>

      <!-- Contenedor del timeline -->
      <div
        class="timeline-container"
        data-aos="fade-up"
        data-aos-delay="100"
      >
        <div id="timeline">
          <div
            v-for="(item, index) in timelineItems"
            :key="index"
            class="timeline-item"
          >
            <div class="timeline-icon text-center p-2">
              <img
                :src="require(`@/assets/${item.icon}`)"
                alt="icono"
                class="img-fluid"
              >
            </div>
            <div :class="['timeline-content', item.align]">
              <p :class="`text-${item.alignText} mb-2`">
                {{ item.time }}
              </p>
              <h2 :class="`text-${item.alignText} font-weight-bold`">
                {{ item.title }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TimelineComponent',
  data() {
    return {
      timelineItems: [
        { time: '18:00hrs', title: 'Ceremonia', icon: 'ceremonia_time.png', align: '', alignText: 'right' },
        { time: '19:00hs', title: 'Recepción', icon: 'recepcion_time.png', align: 'right', alignText: 'left' },
        { time: '19:30hs', title: 'Entrada de los novios', icon: 'entradanovios_time.png', align: '', alignText: 'right' },
        { time: '20:30hs', title: 'Cena', icon: 'cena_time.png', align: 'right', alignText: 'left' },
        { time: '21:30hrs', title: 'Brindis y Vals', icon: 'vals_time.png', align: '', alignText: 'right' },
        { time: '22:00hs', title: 'Baile', icon: 'fiesta_time.png', align: 'right', alignText: 'left' }      ],
    };
  },
};
</script>

<style scoped>

.timeline-container {
  -webkit-box-flex:0;
  -ms-flex:0 0 50%;
  flex:0 0 50%;
  max-width:50%;
  margin: 0 auto;

  
}

#timeline .timeline-item:after, #timeline .timeline-item:before {
  content: '';
  display: block;
  width: 100%;
  clear: both;
}

#timeline {
  width: 100%;
  margin: 30px auto;
  position: relative;
  padding: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
#timeline:before {
  content: "";
  width: 2px;
  height: 100%;
  background: #a78977;
  left: 49.5%;
  top: 0;
  position: absolute;
}

.linea-white #timeline:before {
  background: #fff;
  width: 1px;
}

.linea-black #timeline:before {
  background: #000;
  width: 1px;
}
 #timeline .timeline-item .timeline-content:before {
  border-left: 7px solid #fff;
}

.linea-gold #timeline:before {
  background: #9c916b;
  width: 2px;
}
 #timeline .timeline-item .timeline-content:before {
  border-left: 7px solid #fff;
}

#timeline:after {
  content: "";
  clear: both;
  display: table;
  width: 100%;
}
#timeline .timeline-item {
  margin-bottom: 30px;
  position: relative;
}
#timeline .timeline-item .timeline-icon {
  background: #a78977;
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  left: 49.5%;
  overflow: hidden;
  margin-left: -23px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}

#timeline .timeline-item .timeline-icon svg, .timeline-icon png, #timeline .timeline-item .timeline-icon .fa {
  position: relative;
  top: 13px;
  color: #fff;
}
#timeline .timeline-item .timeline-content {
  width: 43%;
  background: linear-gradient(to bottom, rgba(222, 230, 213, 0.078) 30%, rgba(101, 114, 96, 0.212));
  padding: 5px 20px 10px 20px;
  border:2px dotted #a78977;
  border-radius: 24px;
}
#timeline .timeline-item .timeline-content h2 {
  padding: 10px 20px 0px 20px;
  color: #a78977;
  margin: -20px -20px 0 -20px;
  -webkit-border-radius: 3px 3px 0 0;
  -moz-border-radius: 3px 3px 0 0;
  -ms-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
  font-size: 21px;
  font-weight: lighter;
}
#timeline .timeline-item .timeline-content p {
  padding-top: 6px;
  padding-bottom: 0px;
  font-size: 18px;
}
#timeline .timeline-item .timeline-content:before {
  content: '';
  position: absolute;
  left: 43%;
  top: 20px;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid #a78977;
}
#timeline .timeline-item .timeline-content.right {
  float: right;
}
#timeline .timeline-item .timeline-content.right:before {
  content: '';
  right: 43%;
  left: inherit;
  border-left: 0;
  border-right: 7px solid #a78977;
}

#timeline .timeline-item .timeline-content.right.c-disco:before {
  border-right: 7px solid #fff;
	border-left: 0px;
}
#timeline .timeline-item .timeline-content.c-disco:before {
  border-left: 7px solid #fff;
	border-right: 0px;
}

#timeline .timeline-item .timeline-content.right.c-passion:before {
  border-right: 7px solid #9c916b;
	border-left: 0px;
	
}
#timeline .timeline-item .timeline-content.c-passion:before {
  border-left: 7px solid #9c916b;
	border-right: 0px;
}

#timeline .timeline-item .timeline-content.right.c-black:before {
  border-right: 7px solid #000;
	border-left: 0px;
}
#timeline .timeline-item .timeline-content.c-black:before {
  border-left: 7px solid #000;
	border-right: 0px;
}
#timeline .timeline-item .timeline-content.right.c-musgo:before {
  border-right: 7px solid #9ab09c;
	border-left: 0px;
}
#timeline .timeline-item .timeline-content.c-musgo:before {
  border-left: 7px solid #9ab09c;
	border-right: 0px;
}

#timeline .timeline-item .timeline-content.right.c-rosa:before {
  border-right: 7px solid #e62f83;
	border-left: 0px;
}
#timeline .timeline-item .timeline-content.c-rosa:before {
  border-left: 7px solid #e62f83;
	border-right: 0px;
}

#timeline .timeline-item .timeline-content.right.c-bordo:before {
  border-right: 7px solid #890029;
	border-left: 0px;
}
#timeline .timeline-item .timeline-content.c-bordo:before {
  border-left: 7px solid #890029;
	border-right: 0px;
}

#timeline .timeline-item .timeline-content.right.c-black2:before {
  border-right: 7px solid #000 ;
	border-left: 0px;
}
#timeline .timeline-item .timeline-content.c-black2:before {
  border-left: 7px solid #000 ;
	border-right: 0px;
}

#timeline .timeline-item .timeline-content.right.c-white:before {
  border-right: 7px solid #000;
	border-left: 0px;
}
#timeline .timeline-item .timeline-content.c-white:before {
  border-left: 7px solid #000;
	border-right: 0px;
}
#timeline .timeline-item .timeline-content.right.c-white2:before {
  border-right: 7px solid #fff;
	border-left: 0px;
}
#timeline .timeline-item .timeline-content.c-white2:before {
  border-left: 7px solid #fff;
	border-right: 0px;
}
#timeline .timeline-item .timeline-content.right.c-eucalipto:before {
  border-right: 7px solid #9c916b;
	border-left: 0px;
}
#timeline .timeline-item .timeline-content.c-eucalipto:before {
  border-left: 7px solid #9c916b;
	border-right: 0px;
}




@media screen and (max-width: 768px) {
  #timeline .timeline-item .timeline-content.right:before {
  right: 50%;
  }
  #timeline .timeline-item .timeline-content:before {
    left: 50%;
  }
  #timeline .timeline-item .timeline-content {
  width: 50%;
  padding: 18px 52px 18px 28px;
  }
  .timeline-container {
  -webkit-box-flex:0;
  -ms-flex:0 0 100%;
  flex:0 0 100%;
  max-width:100%;
  }
}

/* Animation CSS */

/* icon animation */
.timeline-icon.is-hidden {
  visibility: hidden;
}
.timeline-icon.animate-it {
  visibility: visible;
   -webkit-animation: bounce-1 0.6s;
   -moz-animation: bounce-1 0.6s;
    animation: bounce-1 0.6s;
}

/* content block animation */
.timeline-content.is-hidden {
  visibility: hidden;
}
.timeline-content.animate-it {
  visibility: visible;
   -webkit-animation: bounce-2 0.6s;
   -moz-animation: bounce-2 0.6s;
    animation: bounce-2 0.6s;
  }

.timeline-content.right.animate-it {
    -webkit-animation: bounce-2-inverse 0.6s;
    -moz-animation: bounce-2-inverse 0.6s;
    animation: bounce-2-inverse 0.6s;
  }

/* ALL animation types called */

@-webkit-keyframes bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }

  100% {
    -moz-transform: scale(1);
  }
}
@keyframes bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }

  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }

  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
.invert { filter: invert(100%); }
.text-bronce{
  font-size: 38px;
  letter-spacing: 5px; /* Espaciado entre letras */

  font-family: 'Great Vibes', cursive !important;
  font-weight: 800;
  margin: 0 30px;
  background: linear-gradient(270deg, #9c916b,  #ded7bc, #5c5640);
  background-size: 200% 100%;
  background-clip: text;
  color: transparent;
  margin-top: 10px;
  animation: animateText 5s linear infinite;
}
#itinerario{
  top: 0; /* Empieza desde la parte superior de la pantalla */
  left: 0; /* Empieza desde el borde izquierdo */
  width: 100vw; /* Ocupa todo el ancho de la pantalla */
  height: 150vh; /* Ocupa todo el alto de la pantalla */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; /* Centra horizontal y verticalmente */
  box-sizing: border-box;
  background: linear-gradient(to bottom, rgba(222, 230, 213, 0.078) 30%, rgba(101, 114, 96, 0.212));
  z-index: 20; /* Asegura que el contenedor esté encima de otros elementos */
  margin: 0; /* Elimina el margen para ajustar el diseño */
  border: none; /* Elimina cualquier borde si no es necesario */
  overflow: hidden; /* Evita el desbordamiento */
}
</style>
